.custom404 {
  :global {
    width: 100%;
    background: linear-gradient(95deg, #233654 -1.13%, #0b1e2b 100%);
    [data-theme="dark"] & {
      background: #1a1a1a;
    }
    .inner {
      position: relative;
      height: 100vh;

      flex-direction: column;
    }

    .content {
      flex-direction: column;

      .image-div {
        &.light {
          display: flex;
        }
        &.dark {
          display: none;
        }
        [data-theme="dark"] & {
          &.light {
            display: none;
          }
          &.dark {
            display: flex;
          }
        }
        @media (max-width: 786px) {
          transform: scale(0.8);
        }
        @media (max-width: 576px) {
          transform: scale(0.7);
        }
      }
      h2 {
        color: #fff;
        font-size: 20px;
        font-weight: 500;
        margin-top: 20px;
        margin-bottom: 40px;
        @media (max-width: 576px) {
          margin-top: -30px;
        }
      }
    }

    .iconBottom {
      display: flex;
      position: absolute;
      bottom: 0;
      animation: bounceXs2 3s linear infinite;
      @media (max-width: 786px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        @media (max-width: 786px) {
          width: 60%;
          height: 60%;
        }
      }
    }
    .v1 {
      left: 0;
      // animation-delay: 0s;
    }
    .v2 {
      right: 0;
      // animation-delay: 1s;
    }
    .v3 {
      left: 33%;
      // animation-delay: 2s;
    }
    .v4 {
      right: 30%;
      // animation-delay: 3s;
    }
    .v5 {
      left: 20%;
      // animation-delay: 4s;
    }
  }
}
